<template>
    <div class="history-container">
        <div class="history-row header">
            <span
                v-for="field in fields"
                :key="field.key"
                class="history-row-title"
            >
                {{ field.label }}
            </span>
        </div>
        <div class="history-rows">
            <div
                v-for="completedItem in completedBucket"
                :key="completedItem.id"
                class="history-row"
            >
                <div class="task-name">
                    {{ alias(completedItem) }}
                </div>
                <div class="date-time">
                    {{ localFormatedDate( completedItem.completedDate, 'DD/MM/YYYY H:mm') }}
                </div>
                <div class="points-won">
                    {{ completedItem.completedPoints }}
                </div>
            </div>
            <div
                v-if="completedBucket.length < completedTasks.length"
                class="load-more"
                @click="loadMore"
            >
                {{ $t('taskHistoryTable.loadMore') }}
            </div>
        </div>
    </div>
</template>

<script>
    import dateConversion from '@/utils/date-conversion'
    export default {
        props: {
            completedTasks: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                completedBucket: [],
                fields: [
                    { key: 'alias', label: this.$t('taskHistoryTable.taskLabel') },
                    { key: 'completedDate', label: this.$t('taskHistoryTable.dateLabel') },
                    { key: 'completedPoints', label: this.$t('taskHistoryTable.pointsLabel') }
                ]
            }
        },
        created() {
            this.completedBucket = this.completedTasks.slice(0, 3)
        },
        methods: {
            localFormatedDate(date, format) {
                return dateConversion.utcToLocalTime(date, format)
            },
            loadMore() {
                this.completedBucket = this.completedTasks.slice(0, this.completedBucket.length + 2)
            },
            alias(task) {
                if (!task.multilingualTask || !task.multilingualTask[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return task.multilingualTask[0].config.alias
            }
        }
    }
</script>

<style scoped>
.history-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem auto 0;
    max-width: 1100px;
    width: 100%;
    padding: 1rem;
}

.history-rows {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
    width: 100%;
}

.history-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    border-top: 1px solid var(--mission-history-border-color);
    padding: 0.5rem;
    background: var(--mission-history-bg-color);
    color: var(--mission-history-text-color);
}

.history-row.header {
    border-top: none;
    padding: 0.5rem 0;
    font-weight: bold;
}

.load-more {
    background: var(--light-secondary-color);
    color: var(--info-color);
    padding: 0.25rem 0;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .history-row {
        padding: 0.75rem 0;
        font-size: 1.1rem;
    }

    .history-row.header {
        font-size: 1.2rem;
        padding: 1rem 0;
    }
}

</style>
